<template>
  <div id="top-nav">
    <div class="mxtian">
      <div class="left">
        <div class="img">
          <router-link to="/">
            <img src="@/assets/img/logo.png" alt="">
          </router-link>
        </div>
      </div>
      <div class="right">
        <router-link to="/">首页</router-link>
        <router-link to="/notes">随笔</router-link>
        <router-link to="/smart">技术</router-link>
        <router-link to="/resources">资源</router-link>
        <router-link to="/tools">在线工具</router-link>
        <router-link to="/about">关于</router-link>
        <div v-if="isLogin" class="user">
          <router-link to="/admin">管理中心</router-link>
          <span @click="logout" class="logout">退出</span>
        </div>
        <!-- <router-link to="/community">社区</router-link>
        <router-link to="/login">登录/注册</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import { getCookie, clearCookie } from "@/utils/cookie"
import router from "@/router"
import { useStore } from "vuex"

export default defineComponent({
  setup() {
    const store = useStore();
    const isLogin = computed(() => store.state.login.isLogin);
    const logout = () => {
      clearCookie("user");
      clearCookie("token");
      store.commit("login/loginSign", false);
      router.push("/");
    }
    onMounted(() => {
      const sign = getCookie("token") && getCookie("user");
      if (sign) {
        store.commit("login/loginSign", true);
      }
    })
    return {
      isLogin,
      logout
    }
  }
})
</script>

<style lang="scss">
  #top-nav {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 11;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.1);
    height: 70px;
    padding: 0 60px;
    
    .mxtian {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      min-width: 1180px;
      max-width: 1380px;

      .left {
        img {
          width: 50px;
          height: 50px;
        }
      }

      .right {
        height: 100%;

        .user {
          display: inline-block;

          .logout {
            cursor: pointer;
            text-align: center;
            display: inline-block;
            color: #CC3333;
            cursor: pointer;
            font-weight: 600;
          }
        }

        .router-link-exact-active {
          color: #2254f4;
          border-bottom: 3px solid #2254f4;
          font-weight: 700;
        }

        a {
          color: black;
          height: 100%;
          display: inline-block;
          line-height: 70px;
          margin: 0 20px;

          &:hover {
            color: #2254f4;
            border-bottom: 3px solid #2254f4;
            font-weight: 700;
          }
        }
      }
    }
    
  }
</style>