<template>
  <div id="index">
    <div class="index-top">
      <top-nav></top-nav>
    </div>
    <!-- 移动端导航 -->
    <div class="mobile-wrap">
      <div class="mobile">
        <div class="left">
          <img @click="openNav" src="@/assets/img/nav.png" alt="">
        </div>
        <div class="right">
          <span>木小天博客</span>
        </div>
      </div>
      <div class="mask-wrap">
        <div @click="closeNav" class="mask"></div>
        <div :style="{transform: `translateX(${position}%)`}" class="nav">
          <div class="logo">
            <img src="@/assets/img/logo.png" alt="">
          </div>
          <router-link @click="closeNav" to="/">首页</router-link>
          <router-link @click="closeNav" to="/notes">随笔</router-link>
          <router-link @click="closeNav" to="/smart">技术</router-link>
          <router-link @click="closeNav" to="/resources">资源</router-link>
          <router-link @click="closeNav" to="/tools">工具</router-link>
          <router-link @click="closeNav" to="/about">关于</router-link>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <web-footer></web-footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import topNav from "@/components/top-nav/top-nav.vue"
import webFooter from "@/components/web-footer/web-footer.vue"

export default defineComponent({
  components: {
    topNav,
    webFooter
  },
  setup() {
    const position = ref(-100);

    const openNav = () => {
      position.value = 0;
      (document.querySelector(".mask") as any).style.display = "block";
    }
    const closeNav = () => {
      position.value = -100;
      (document.querySelector(".mask") as any).style.display = "none";
    }
    return {
      position,
      openNav,
      closeNav
    }
  }
})
</script>

<style lang="scss" scoped>
  #index {

    .mobile-wrap {
      height: 55px;
      margin-bottom: 20px;
      display: none;
    }

    .index-top {
      display: block;
      height: 70px;
      margin-bottom: 30px;
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    #index {

      .index-top {
        display: none;
      }

      .mobile-wrap {
        display: block;

        .mobile {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          z-index: 1;
          background: #fff;
          // box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 55px;
          padding: 0 10px;

          .left {

            img {
              display: block;
              width: 32px;
              height: 32px;
            }
          }

          .right {
            color: #333;
          }
        }

        .mask-wrap {
          position: fixed;
          width: 100%;
          z-index: 2;
          
          .mask {
            display: none;
            width: 100%;
            height: 100vh;
            background: rgba(0,0,0,.2);
            z-index: 2;
          }

          .nav {
            width: 60%;
            height: 100vh;
            background: white;
            transition: all .3s;
            position: absolute;
            top: 0;
            left: 0;

            .router-link-exact-active {
              color: #2254f4;
              border-left: 4px solid #2254f4;
              font-weight: 700;
            }

            .logo {
              padding: 15px 0;
              padding-left: 30px;

              img {
                width: 50px;
                height: 50px;
              }
            }

            a {
              display: block;
              line-height: 40px;
              color: #444950;
              font-size: 13px;
              padding-left: 30px;
              text-align: left;
              margin: 0 0 10px;
              border-bottom: 0;
              border-left: 4px solid transparent;
            }
          }
        }
      }
    }
  }
</style>