
import { defineComponent, ref } from "vue"
import topNav from "@/components/top-nav/top-nav.vue"
import webFooter from "@/components/web-footer/web-footer.vue"

export default defineComponent({
  components: {
    topNav,
    webFooter
  },
  setup() {
    const position = ref(-100);

    const openNav = () => {
      position.value = 0;
      (document.querySelector(".mask") as any).style.display = "block";
    }
    const closeNav = () => {
      position.value = -100;
      (document.querySelector(".mask") as any).style.display = "none";
    }
    return {
      position,
      openNav,
      closeNav
    }
  }
})
