<template>
  <div id="web-foot">
    <div class="top">
      <div class="center">
        <div class="left">
          <router-link to="/">首页</router-link>
          <router-link to="/notes">随笔</router-link>
          <router-link to="/smart">技术</router-link>
          <router-link to="/resources">资源</router-link>
          <router-link to="/tools">在线工具</router-link>
          <router-link to="/about">关于</router-link>
        </div>
        <div class="right">
          <img
            style="display: inline-block"
            src="@/assets/img/logo.png"
            alt=""
          />
          <span style="color: #666">追踪着鹿的猎人是看不见山的。</span>
        </div>
      </div>
    </div>
    <div class="other center">
      <p>
        站点声明：本站转载作品版权归原作者及来源网站所有，原创内容作品版权归作者所有，任何内容转载、商业用途等均须联系原作者并注明来源。
      </p>
      <p>
        © 2020 The Pride & Joy of an Artisan. · Designed By mxtian · Powered By
        小帅 <a style="color: #666;" href="https://beian.miit.gov.cn" target="_blank">鲁ICP备2020042638号-1</a>
      </p>
      <div class="links">
        友情链接：
        <a
          v-for="item in links.data"
          :key="item.id"
          :href="item.link"
          target="_blank"
        >
          {{ item.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { getLinks } from "@/api";

export default defineComponent({
  setup() {
    const links = reactive({ data: null });
    onMounted(() => {
      const hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?413e27cd51d2ff5fcdd96db284d36c8c";
      const s: any = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
      getLinks().then((res: any) => {
        links.data = res.data.data;
      });
    });
    return {
      links,
    };
  },
});
</script>

<style lang="scss" scoped>
#web-foot {
  margin-top: 20px;
  background: #fff;
  padding-bottom: 30px;
  border-top: 1px solid #ebebeb;

  .top {
    background: #fff;
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb;
    color: #999;
    font-size: 12px;
    line-height: 20px;

    .center {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .left {
      a {
        color: #333;
        margin-right: 20px;
      }
    }

    .right {
      img {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 6px;
        margin-bottom: 3px;
        vertical-align: middle;
      }
    }
  }

  .other {
    margin-top: 20px;

    p {
      color: #666;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .links {
      font-size: 12px;

      a {
        color: #666;
        margin-right: 10px;

        &:hover {
          color: #2254f4;
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: 800px) {
  #web-foot {
    .top {
      display: none;
    }
  }
}
</style>