
import { defineComponent, onMounted, reactive } from "vue";
import { getLinks } from "@/api";

export default defineComponent({
  setup() {
    const links = reactive({ data: null });
    onMounted(() => {
      const hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?413e27cd51d2ff5fcdd96db284d36c8c";
      const s: any = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
      getLinks().then((res: any) => {
        links.data = res.data.data;
      });
    });
    return {
      links,
    };
  },
});
